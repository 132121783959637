<template>
  <a-layout style="overflow: auto">
    <a-layout-content
      style="
        background: #fff;
        margin: 0;
        overflow: hidden;
        position: relative;
        min-width: 1920px;
        min-height: 928px;
      "
    >
      <div style="display: flex; flex-direction: column" class="img background">
        <!-- 头部 -->
        <div style="height: 77px" class="flow-d-row">
          <div class="two flow-d-row" style="flex: 1">
            <div style="flex: 1">
              <div
                style="
                  height: 45px;
                  line-height: 45px;
                  float: left;
                  width: 300px;
                  padding: 0 10px;
                "
              >
                <a-icon
                  style="font-size: 30px; margin-top: 8px"
                  type="clock-circle"
                />
                <span style="font-size: 20px; margin: 0 10px">{{
                  times | time
                }}</span>
                <span style="font-size: 15px; margin-right: 5px">{{
                  daylist.lunar
                }}</span>
                <span style="font-size: 15px">{{ daylist.weekday }}</span>
              </div>
            </div>
            <div
              class="top-left background"
              style="flex: 1; height: 55px; padding: 0 1%"
            >
              <img
                style="float: right"
                src="./../../assets/slices/circle.png"
                alt=""
              />
            </div>
          </div>
          <div style="width: 40px"></div>
          <div class="one flow-d-row" style="flex: 1">
            <div
              class="top-right background"
              style="flex: 1; height: 55px; padding: 0 1%"
            >
              <img src="./../../assets/slices/circle-right.png" alt="" />
            </div>
            <div style="flex: 1">
              <div style="width: 182px; float: right" class="flow-d-row">
                <img
                  v-if="predict.code === '0'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/0@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '1'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/1@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '2'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/2@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '3'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/3@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '4'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/4@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '5'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/5@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '6'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/6@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '7'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/7@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '8'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/8@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '9'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/9@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '10'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/10@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '11'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/11@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '12'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/12@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '13'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/13@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '14'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/14@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '15'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/15@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '16'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/16@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '17'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/17@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '18'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/18@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '19'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/19@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '20'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/20@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '21'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/21@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '22'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/22@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '23'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/23@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '24'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/24@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '25'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/25@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '26'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/26@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '27'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/27@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '28'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/28@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '29'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/29@1x.png"
                  alt=""
                />
                <img
                  v-else-if="predict.code === '30'"
                  style="width: 40px; height: 30px; margin-top: 5px"
                  src="@/assets/black/30@1x.png"
                  alt=""
                />
                <div
                  style="margin: 0 8px; font-weight: 600"
                  class="flow-d-column"
                >
                  <span style="font-size: 15px">{{ predict.name }}</span>
                  <span>{{ predict.temperature }}℃</span>
                </div>
                <span style="margin-top: 5px">{{ predict.text }}</span>
              </div>
            </div>
          </div>
          <div class="title">{{ projectName }}</div>
        </div>
        <div
          class="flow-d-row"
          style="height: calc(100% - 88px); margin-top: 11px"
        >
          <!-- 左 -->
          <div class="map-left flow-d-column">
            <div style="margin-top: -35px">
              <div class="map-left-title">招商数据总览</div>
              <div class="map-left-main">
                <div
                  class="flow-d-row"
                  style="
                    justify-content: space-around;
                    padding: 0 35px;
                    margin: 20px 0;
                  "
                >
                  <div>
                    <p class="map-left-smalltext">招商完成率</p>
                    <span class="map-left-bigtext qing"
                      >{{
                        (
                          (rentRatelist.rentedArea /
                            (rentRatelist.notRentArea +
                              rentRatelist.rentedArea +
                              rentRatelist.rentingArea)) *
                          100
                        ).toFixed(2)
                      }}%</span
                    >
                  </div>
                  <div>
                    <p class="map-left-smalltext">未签约面积</p>
                    <span class="map-left-bigtext qing">{{
                      rentRatelist.notRentArea
                    }}</span>
                  </div>
                  <div>
                    <p class="map-left-smalltext">未签约铺位</p>
                    <span class="map-left-bigtext qing">{{
                      rentRatelist.notRentNum
                    }}</span>
                  </div>
                </div>
                <!-- <div
                  class="flow-d-row"
                  style="justify-content: space-around;padding:0 24px"
                >
                  <div>
                    <p class="map-left-smalltext">已签约品牌</p>
                    <span style="color:#FF5597" class="map-left-bigtext"
                      >50%</span
                    >
                  </div>
                  <div>
                    <p class="map-left-smalltext">跟进中品牌</p>
                    <span style="color:#FF5597" class="map-left-bigtext"
                      >50%</span
                    >
                  </div>
                  <div>
                    <p class="map-left-smalltext">无意向品牌</p>
                    <span style="color:#FF5597" class="map-left-bigtext"
                      >50%</span
                    >
                  </div>
                </div> -->
              </div>
            </div>
            <div>
              <div class="map-left-title">客户、铺位、洽谈进度</div>
              <div class="map-left-main flow-d-coolumn">
                <div
                  v-for="item in talklist"
                  :key="item.talkId"
                  class="map-left-div flow-d-row"
                >
                  <div>
                    <img
                      class="div-img"
                      src="../../assets/4fce37a73c4a1f5087623fd1fe3f6d01.jpeg"
                      alt=""
                    />
                  </div>
                  <div class="map-16" style="padding: 5px 10px; flex: 1">
                    <a-tooltip>
                      <template slot="title">
                        {{ item.clientName }}
                      </template>
                      <span>{{
                        item.clientName.length > 10
                          ? item.clientName.slice(0, 10) + '...'
                          : item.clientName
                      }}</span>
                    </a-tooltip>
                    <div class="div-pai">{{ item.stores }}</div>
                  </div>
                  <div
                    class="div-img"
                    style="border-left: 1px solid #fff; line-height: 25px"
                  >
                    <p class="map-16" style="text-align: center">
                      {{ item.belongToName }}
                    </p>
                    <p class="map-13" style="text-align: center">
                      进度 : {{ item.successRate }}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style="flex: 1; padding-bottom: 50px">
              <div class="map-left-title">招商情况统计</div>
              <div
                class="flow-d-column"
                style="
                  height: calc(100% - 40px);
                  justify-content: center;
                  align-items: center;
                "
              >
                <div
                  id="map-left-huan"
                  style="width: 100%; height: 295px"
                ></div>
              </div>
            </div>
            <img
              style="
                height: 800px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
              "
              src="./../../assets/slices/left-back.png"
              alt=""
            />
          </div>
          <div class="map-center flow-d-column" style="flex: 1">
            <!-- title -->
            <div>
              <div
                class="map-top-number flow-d-row"
                style="justify-content: center; align-items: center"
              >
                <span style="font-size: 22px; color: #52feff"
                  >上月租金(万) &nbsp;&nbsp;</span
                >
                <span v-for="item in rent" :key="item" class="number">{{
                  item
                }}</span>
              </div>
              <div class="flow-d-row" style="justify-content: center">
                <div class="map-center-top flow-c-ju">
                  <div class="map-16">已签约面积(m²)</div>
                  <div class="center-top-title qing">
                    {{ rentRatelist.rentedArea }}
                  </div>
                </div>
                <div style="margin: 0 25px" class="map-center-top flow-c-ju">
                  <div class="map-16">已租铺位数</div>
                  <div class="center-top-title qing">
                    {{ rentRatelist.rentedNum }}
                  </div>
                </div>
                <div style="width: 154px" class="map-center-top flow-c-ju">
                  <div class="map-16">商户入驻率</div>
                  <div class="center-top-title qing">
                    {{
                      (
                        (rentRatelist.rentedNum /
                          (rentRatelist.notRentNum +
                            rentRatelist.rentedNum +
                            rentRatelist.rentingNum)) *
                        100
                      ).toFixed(2)
                    }}%
                  </div>
                </div>
              </div>
            </div>
            <!-- 图 -->
            <div class="flow-d-column" style="padding: 10px 24px; flex: 1">
              <!-- 地图 -->
              <div>
                <div class="center-woed-title flow-d-column">
                  项目预览&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style="width: 100px" class="titleback"></span>
                </div>
                <div
                  id="main"
                  style="height: 390px; width: 100%; text-align: center"
                ></div>
              </div>
              <div style="flex: 1" class="flow-d-row">
                <div style="flex: 1">
                  <div class="center-woed-title flow-d-column">
                    铺位签约统计
                    <span class="titleback"></span>
                  </div>
                  <div
                    class="flow-d-row"
                    style="
                      height: calc(100% - 40px);
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div>
                      <div style="font-size: 14px" class="map-16">
                        铺位总数量
                      </div>
                      <div
                        style="font-size: 24px"
                        class="center-top-title qing"
                      >
                        {{
                          rentRatelist.notRentNum +
                          rentRatelist.rentedNum +
                          rentRatelist.rentingNum
                        }}
                      </div>
                    </div>
                    <a-progress
                      style="margin: 0 10px"
                      width="140px"
                      strokeWidth="14"
                      type="circle"
                      :percent="
                        (
                          rentRatelist.rentedNum /
                          parseInt(
                            rentRatelist.notRentNum +
                              rentRatelist.rentedNum +
                              rentRatelist.rentingNum
                          )
                        ).toFixed(2) * 100
                      "
                    >
                      <template #format="percent">
                        <span style="color: #e9e9e9; font-size: 16px"
                          >{{ percent }}%</span
                        >
                      </template>
                    </a-progress>
                    <div>
                      <div style="font-size: 14px" class="map-16">
                        已签约数量
                      </div>
                      <div
                        style="font-size: 24px"
                        class="center-top-title qing"
                      >
                        {{ rentRatelist.rentedNum }}
                      </div>
                    </div>
                  </div>
                </div>
                <div style="flex: 1">
                  <div class="center-woed-title flow-d-column">
                    楼层业务分析
                    <span class="titleback"></span>
                  </div>
                  <div
                    class="flow-d-column"
                    style="
                      height: calc(100% - 40px);
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div id="floor" style="height: 231px; width: 100%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右 -->
          <div class="map-right">
            <div style="margin-top: -35px; height: 38%; width: 97%">
              <div class="map-left-title">年收租金统计</div>
              <div
                style="min-height: 260px; margin-left: 15px"
                id="yearfee"
              ></div>
            </div>
            <div class="flow-d-column" style="height: 30%; width: 97%">
              <div class="map-left-title">未收租金分析</div>
              <div
                id="unPayedFee"
                style="width: 100%; height: 200px; margin-left: 15px"
              ></div>
            </div>
            <div class="flow-d-column" style="height: 30%; width: 97%">
              <div class="map-left-title">已收租金分析</div>
              <div
                id="payedFee"
                style="width: 100%; height: 200px; margin-left: 15px"
              ></div>
            </div>
            <img
              style="
                height: 800px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
              "
              src="./../../assets/slices/right-back.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const statusMap = {
  0: {
    status: 'error',
  },
  1: {
    status: 'processing',
  },
  2: {
    status: 'success',
  },
}
import http from './../../http'
import * as echarts from 'echarts'
import ChinaGeo from '@/assets/map/json/china.json'
import WorldGeo from '@/assets/map/json/world.json'
import 'echarts/lib/component/geo'
import 'echarts/lib/chart/map'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/visualMap'
import moment from 'moment'
export default {
  data() {
    return {
      times: '',
      welcomebusiness: [],
      rentRatelist: {
        notRentArea: 0,
        notRentNum: 0,
        rentedArea: 0,
        rentedNum: 0,
        rentingArea: 0,
        rentingNum: 0,
      },
      rent: '',
      predict: {
        code: '',
        name: '',
        temperature: '',
        text: '',
      },
      dashbusinessType: [],
      talklist: [],
      projectName: '',
      maplist: [],
      totallist: {
        types: [],
        months: [],
        rent: [],
        fee: [],
        surety: [],
        discount: [],
        other: [],
      },
      payedFee: { months: [], rent: [] },
      unPayedFee: { months: [], rent: [] },
      daylist: {
        lunar: '',
        weekday: '',
        // time: "2021-12-21T14:51:51.3817693",
      },
      days: '',
    }
  },
  filters: {
    statusTypeFilter(type) {
      return statusMap[type].status
    },
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split(' ')
        return times[1]
      }
    },
  },
  methods: {
    option() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('map-left-huan'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: '招商统计',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: '{b}\n{d}%',
                lineHeight: 20,
                textStyle: {
                  color: '#d9d9d9',
                },
              },
            },
            labelLine: {
              length: 20,
              length2: 0,
              maxSurfaceAngle: 80,
            },
            // roseType: "area",
            data: this.welcomebusiness, //数据
          },
        ],
      })
    },
    option2() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('floor'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: '楼层分析',
            type: 'pie',
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: '{b}\n{d}%',
                lineHeight: 20,
                textStyle: {
                  color: '#d9d9d9',
                },
              },
            },
            labelLine: {
              length: 11,
              length2: 7,
              maxSurfaceAngle: 80,
            },
            data: this.dashbusinessType,
          },
        ],
      })
    },
    option3() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('yearfee'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          data: this.totallist.types,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#fff',
              },
            },
            data: this.totallist.months,
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#fff',
              },
            },
          },
        ],
        series: [
          {
            name: '租金',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            label: {
              normal: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#d9d9d9',
                },
              },
            },
            emphasis: {
              focus: 'series',
            },
            data: this.totallist.rent,
          },
          {
            name: '物业费',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: this.totallist.fee,
          },
          {
            name: '保证金',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: this.totallist.surety,
          },
          {
            name: '优惠',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: this.totallist.discount,
          },
          {
            name: '其他',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: this.totallist.other,
          },
        ],
      })
    },
    optionword() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'))
      const mapType = 'china'
      echarts.registerMap(mapType, mapType === 'china' ? ChinaGeo : WorldGeo)
      // 绘制图表
      myChart.setOption({
        tooltip: {
          formatter: param => {
            // console.log(param);
            return [
              '项目名称' + ':' + '     ' + param.data.text,
              ' 项目地点' + ':' + '     ' + param.data.name,
            ].join('<br>') //显示文字
          },
        },
        geo: {
          map: mapType,
          zoom: 1,
          roam: false, // 鼠标滑动放大缩小
          itemStyle: {
            areaColor: '#f5f5f5',
            borderWidth: '.5',
            borderColor: '#999',
          },
        },
        visualMap: {
          itemWidth: 4,
          min: 0,
          max: 10000,
          hoverLink: false,
          showLabel: true,
          textStyle: {
            color: 'rgba(0,0,0,0.65)',
          },
          pieces: [
            {
              label: ' ',
              color: '#EF1A55',
            },
          ],
        },
        series: [
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              period: 4,
              brushType: 'stroke',
            },
            symbolSize: 15,
            itemStyle: {
              color: 'blue',
              opacity: 10,
            },
            data: this.maplist, //数据
          },
        ],
      })
    },
    optionpayedFee() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('payedFee'))
      // 绘制图表
      myChart.setOption({
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
          type: 'category',
          data: this.payedFee.months,
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        grid: {
          top: '15%', // 组件离容器上侧的距离,百分比字符串或整型数字
          left: '5%', // 组件离容器左侧的距离,百分比字符串或整型数字
          right: '5%',
          bottom: '3%',
          containLabel: true, //grid 区域是否包含坐标轴的刻度标签，
        },
        series: [
          {
            data: this.payedFee.rent,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          },
        ],
      })
    },
    optionunPayedFee() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('unPayedFee'))
      // 绘制图表
      myChart.setOption({
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
          type: 'category',
          data: this.unPayedFee.months,
        },
        grid: {
          top: '15%', // 组件离容器上侧的距离,百分比字符串或整型数字
          left: '5%', // 组件离容器左侧的距离,百分比字符串或整型数字
          right: '5%',
          bottom: '3%',
          containLabel: true, //grid 区域是否包含坐标轴的刻度标签，
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
        },
        series: [
          {
            data: this.unPayedFee.rent,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          },
        ],
      })
    },
    async getdashboardtotalFee() {
      try {
        const res = await http.getdashboardtotalFee()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.totallist = data
          setTimeout(() => {
            this.option3()
          }, 100)
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getdashboardday() {
      try {
        const res = await http.getdashboardday()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.times = data.day.time.split('.')[0]
          this.daylist = data.day
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getdashboardpayedFee() {
      try {
        const res = await http.getdashboardpayedFee()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.payedFee = data
          setTimeout(() => {
            this.optionpayedFee()
          }, 100)
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    //未收
    async getdashboardunPayedFee() {
      try {
        const res = await http.getdashboardunPayedFee()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.unPayedFee = data
          setTimeout(() => {
            this.optionunPayedFee()
          }, 100)
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getdashboardmap() {
      try {
        const res = await http.getdashboardmap()
        const { success, data } = res.data
        if (success) {
          this.maplist = [data.map]
          setTimeout(() => {
            this.optionword()
          }, 100)
          //   console.log(this.maplist);
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getwelcomebusiness() {
      try {
        const res = await http.getwelcomebusiness()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          let a = []
          data.names.forEach((item, index) => {
            a.push({
              name: item,
              value: data.values[index],
            })
          })
          this.welcomebusiness = a
          setTimeout(() => {
            this.option()
          }, 100)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getdashboardbusinessTypes() {
      try {
        const res = await http.getdashboardbusinessTypes()
        const { success, data } = res.data
        if (success) {
          let a = []
          data.names.forEach((item, index) => {
            a.push({
              name: item,
              value: data.values[index],
            })
          })
          this.dashbusinessType = a
          //   console.log(data);
          setTimeout(() => {
            this.option2()
          }, 100)
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getdashboardtalks() {
      try {
        const res = await http.getdashboardtalks()
        const { success, data } = res.data
        if (success) {
          let talk = []
          data.talks.forEach((item, index) => {
            if (index < 3) {
              talk.push(item)
            }
            this.talklist = talk
          })
          //   console.log(this.talklist);
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getwelcomerentRate() {
      try {
        const res = await http.getwelcomerentRate()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.rentRatelist = data.rentRate
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getdashboardlastRent() {
      try {
        const res = await http.getdashboardlastRent()
        const { success, data } = res.data
        if (success) {
          let a = (data.rent / 10000).toFixed(2) + ''
          this.rent = a.split('')
          //   console.log(this.rent);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getweathercity() {
      try {
        const res = await http.getweathercity()
        // console.log(res.data);
        const { success, data } = res.data
        if (success) {
          this.predict = data.weather
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    settime() {
      setInterval(() => {
        this.beforeDestroy()
        this.getdashboardtotalFee()
        this.getdashboardday()
        this.getdashboardpayedFee()
        this.getdashboardunPayedFee()
        this.getdashboardmap()
        this.getwelcomebusiness()
        this.getwelcomerentRate()
        this.getdashboardlastRent()
        this.getdashboardbusinessTypes()
        this.getdashboardtalks()
      }, 300000)
    },
    timer() {
      return setInterval(() => {
        this.times = moment(this.times)
          .add(1, 's')
          .format('YYYY-MM-DD HH:mm:ss')
      }, 1000)
    },
    beforeDestroy() {
      window.clearInterval(this.timer)
      this.timer = null
      this.getdashboardday()
      this.timer()
    },
  },
  mounted() {
    this.getdashboardday()
    this.getdashboardtotalFee()
    this.getdashboardpayedFee()
    this.getdashboardunPayedFee()
    this.getdashboardmap()
    this.getwelcomebusiness()
    this.getwelcomerentRate()
    this.getdashboardlastRent()
    this.getweathercity()
    this.getdashboardbusinessTypes()
    this.getdashboardtalks()
    this.projectName = JSON.parse(
      localStorage.getItem('store-userlist')
    ).projectInfo.projectName
    this.timer()
    this.settime()
  },
}
</script>
<style lang="less" scoped>
* {
  color: #d9d9d9;
}
p {
  margin: 0;
  padding: 0;
}
.ant-layout {
  height: 100%;
}
.qing {
  color: #52feff;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.flow-d-column {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}
.flow-c-ju {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.background {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.img {
  height: 100%;
  width: 100%;
  z-index: 1;
  background-image: url('./../../assets/slices/bg.png');
}
.two {
  padding-right: 13.5%;
  background-image: url('./../../assets/slices/top-left.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.one {
  background-image: url('./../../assets/slices/top-right.png');
  background-repeat: no-repeat;
  padding-left: 13.5%;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.title {
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  font-size: 28px;
}
.top-right {
  background-image: url('./../../assets/slices/word-right.png');
}
.top-left {
  background-image: url('./../../assets/slices/word.png');
}
.map-left {
  min-width: 500px;
  width: 26%;
  position: relative;
  padding-right: 40px;
  .map-left-title {
    height: 35px;
    font-size: 17px;
    text-align: center;
    line-height: 35px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: url('./../../assets/slices/title.png') no-repeat center center;
  }
  .map-left-div {
    position: relative;
    margin: 10px auto;
    width: 80%;
    border-radius: 8px;
    height: 63px;
    background-color: #063279;
    .div-img {
      width: 75px;
      height: 53px;
      margin: 5px;
    }
  }
  .map-left-smalltext {
    font-size: 13px;
  }
  .map-left-bigtext {
    font-size: 28px;
  }
}
.map-center {
  .map-top-number {
    margin: 0 auto 17px;
    width: 430px;
    height: 65px;
    line-height: 60px;
    background-color: #0b225e;
  }
  .map-center-top {
    background-color: #0b225e;
    width: 232px;
    height: 75px;
    border-radius: 8px;
  }
  .center-top-title {
    font-size: 28px;
    .center-top-text {
      font-size: 14px;
    }
  }
  //   图
  .center-woed-title {
    font-size: 20px;
    width: 120px;
    height: 40px;
    text-align: center;
    position: relative;
  }
  .titleback {
    display: block;
    width: 100%;
    margin-top: -5px;
    height: 5px;
    background: url('./../../assets/slices/titleback.png') no-repeat center
      center;
  }
}
.map-right {
  min-width: 500px;
  width: 26%;
  position: relative;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 10px;
  .map-left-title {
    height: 35px;
    font-size: 17px;
    text-align: center;
    line-height: 35px;
    margin-top: 12px;
    margin-bottom: 10px;
    background: url('./../../assets/slices/title.png') no-repeat center center;
  }
}
.number {
  margin-right: 6px;
  width: 25px;
  text-align: center;
  font-size: 35px;
  background-color: #ef1a55;
  height: 45px;
  line-height: 45px;
}
.div-pai {
  width: 70px;
  height: 20px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  bottom: 8px;
  left: 100px;
  background-color: #0c5296;
  border-radius: 5px;
}
#map-left-huan {
  margin-top: -18px;
  height: 250px;
  z-index: 10;
}
.map-16 {
  font-size: 16px;
}
.map-13 {
  font-size: 13px;
}
</style>
